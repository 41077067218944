@import './../../../../scss/theme-bootstrap';

.product-accordion {
  border-bottom: 1px solid $color-stone-gray;
  .product-quickshop--inverted-white &,
  .product-full--inverted-white & {
    border-color: $color-light-stone-gray;
  }
}

.product-accordion__item {
  position: relative;
  border-top: 1px solid $color-stone-gray;
  .product-quickshop--inverted-white &,
  .product-full--inverted-white & {
    border-color: $color-light-stone-gray;
  }
}

.product-accordion__btn {
  @include icon('plus');
  @include tracking(100);
  cursor: pointer;
  font-size: 10px;
  line-height: 16px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 13px 0 11px 0;
  min-height: 40px;
  @include breakpoint($medium-up) {
    font-size: 12px;
    line-height: 22px;
    padding-top: 15px;
    padding-bottom: 13px;
    min-height: 50px;
  }
  &:before {
    position: absolute;
    top: 14px;
    #{$rdirection}: 10px;
    color: $color-medium-stone-gray;
    @include breakpoint($medium-up) {
      top: 17px;
    }
    .product-quickshop--inverted-white &,
    .product-full--inverted-white & {
      color: $color-black;
    }
  }
  &.active {
    @include icon('minus');
  }
}

.product-accordion__panel {
  color: $color-button-light-gray;
  font-size: 12px;
  line-height: 17px;
  padding: 10px 0 30px 0;
  a {
    color: $color-white;
    border: 0;
    text-decoration: underline;
    &:hover {
      color: $color-white;
    }
  }
  .product-quickshop--inverted-white &,
  .product-full--inverted-white & {
    a {
      color: $color-black;
      &:hover {
        color: $color-black;
      }
    }
  }
}
